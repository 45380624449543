.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-text {
  animation: fadeIn 3.5s ease-in-out infinite alternate;
  color: darkgray;
  font-size: 1.1rem;
  text-align: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media and (max-width: 430px) {
  .loading-text {
    font-size: 0.9rem;
  }
}
