.App {
  text-align: center;
}

.welcome-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  max-width: 766px;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  height: 95vh;
}

.chat-container {
  height: calc(100% - 50px);
  overflow-y: auto;
  margin-bottom: 20px;
}

.chat-response,
.chat-question {
  display: flex;
  gap: 1rem;
}

.response-text {
  width: 100%;
}

#root {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 96%;
  }
}

.btn {
  width: 140px;
}

.welcome-text {
  font-size: 1.25rem;
  text-align: center;
  margin: 20px 0;
  font-weight: 500;
  letter-spacing: 0.0075rem;
  line-height: 1.6;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

@media screen and (max-width: 430px) {
  .btn {
    width: 100%;
  }
  .welcome-text {
    font-size: 1.05rem;
  }

  .question-text,
  .response-text {
    font-size: 0.9rem;
  }
}

.timeout-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timeout-text {
  color: darkgray;
  font-size: 1.1rem;
  text-align: center;
}
